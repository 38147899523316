import { parseBanner } from 'components/Banner/parsers';
import { parseSymptomsList } from 'components/SymptomsIllustrations/parsers';
import parseFAQBanner from 'components/FaqBanner/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';

import parseBodyPain from 'components/BodyPain/parsers';
import { IUmbracoHubIncomeData, IUmbracoHubParsedData } from './models';

export const parseNameToId = (name) =>
  name &&
  name
    .trim()
    .toLowerCase()
    .replace(/[^A-Z0-9]/gi, '-');

const parseTagsLists = (tagsListToParse) => {
  return tagsListToParse.map(({ properties: { tagsListTitle, tagsList, tagsFormat } }) => {
    const parsedTagsList = tagsList.map(({ properties: { tagItem, tagOverlayImage } }) => ({
      link: tagItem[0].relatedPage,
      name: tagItem[0].title,
      overlayImage: tagOverlayImage?.[0]?.properties,
    }));

    return {
      tagsListTitle,
      tagsList: parsedTagsList,
      tagsFormat,
    };
  });
};

export const parseExploreComponent = (item) => {
  return {
    articleSelector: item.articleSelector?.[0]?.properties,
    exploreTitle: item.exploreTitle,
    exploreDescription: item.exploreDescription,
    exploreArticlesTitle: item.exploreArticlesTitle,
    exploreArticlesDescription: item.exploreArticlesDescription,
    exploreColor: item.exploreColor,
    sectionNameForSubNav: parseNameToId(item.sectionNameForSubNav),
    exploreTagsList: parseTagsLists(item.exploreTagsList),
    exploreFeaturedArticles: item.exploreFeaturedArticles?.[0]?.properties?.featuredArticles,
    featuredArticlesCTAButton: item.exploreFeaturedArticles?.[0]?.properties
      ?.featuredItemsSectionCTA?.length
      ? {
          ...parseNFButton(
            item.exploreFeaturedArticles?.[0]?.properties?.featuredItemsSectionCTA?.[0]?.properties
          ),
        }
      : undefined,
    exploreRecommendedProducts: item.exploreRecommendedProducts?.[0]?.properties,
    RecommendedProductsCTAButton: item.exploreRecommendedProducts?.[0]?.properties
      ?.exploreProductsCTA?.length
      ? {
          ...parseNFButton(
            item.exploreRecommendedProducts?.[0]?.properties?.exploreProductsCTA?.[0]?.properties
          ),
        }
      : undefined,
    pageName: item.pageName,
  };
};

const parseUmbracoHubIncomeData = (param: IUmbracoHubIncomeData): IUmbracoHubParsedData => {
  const subNavSections = param.allUmbracoHub.nodes[0].body
    .map(
      (item) =>
        item.properties.sectionNameForSubNav && {
          title: item.properties.sectionNameForSubNav,
          id: parseNameToId(item.properties.sectionNameForSubNav),
        }
    )
    .filter(Boolean);

  const result = param.allUmbracoHub.nodes[0].body.map((item, index) => {
    let itemParsedData;
    switch (item.structure) {
      case 'Page Banner':
        itemParsedData = {
          ...parseBanner(item.properties, {
            breadCrumbs: param.breadCrumbs,
          }),
          subNavItems: subNavSections,
        };
        break;
      case 'Symptoms List':
        itemParsedData = parseSymptomsList(item.properties);
        break;
      case 'FAQ Banner':
        itemParsedData = parseFAQBanner(item.properties);
        break;
      case 'Explore':
        itemParsedData = parseExploreComponent(item.properties);
        break;
      case 'BannerImage':
        itemParsedData = {
          title: item?.properties?.title,
          bannerDescription: item?.properties?.bannerDescription,
          image: item?.properties?.imageBanner,
          bannerImageButton: item?.properties?.bannerImageButton
            ? { ...parseNFButton(item.properties.bannerImageButton[0].properties) }
            : undefined,
        };
        break;
      case 'Body Pain':
        itemParsedData = parseBodyPain(item.properties);
        break;
      case 'Table':
        itemParsedData = {
          content: item?.properties?.table,
        };
        break;
      case 'Quote':
        itemParsedData = {
          content: item?.properties?.quote,
          background: item?.properties?.quoteBackgroundColor,
        };
        break;
      default:
    }

    return {
      ...item,
      key: item.structure + index,
      structure: item.structure,
      properties: itemParsedData,
    };
  });

  return result;
};

export default parseUmbracoHubIncomeData;
